// Generated by Framer (667f196)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["oAHlLDthi"];

const variantClassNames = {oAHlLDthi: "framer-v-12qxihx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "oAHlLDthi", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "oAHlLDthi", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1Ogk5", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-12qxihx", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"oAHlLDthi"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-1u5hy5e"} layout={"position"} layoutDependency={layoutDependency} layoutId={"FIEwrZkQ3-shape"} opacity={1} radius={0} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 39 39\"><path d=\"M 38.468 26.466 L 33.142 23.375 L 38.468 20.276 C 38.742 20.115 38.911 19.82 38.911 19.5 C 38.911 19.18 38.742 18.885 38.468 18.724 L 33.149 15.625 L 38.44 12.548 C 38.589 12.47 38.713 12.351 38.797 12.203 C 38.916 11.997 38.949 11.752 38.889 11.522 C 38.829 11.291 38.68 11.094 38.475 10.974 L 19.912 0.191 C 19.635 0.033 19.297 0.033 19.02 0.191 L 0.457 10.974 C 0.179 11.134 0.008 11.432 0.008 11.754 C 0.008 12.076 0.179 12.374 0.457 12.534 L 5.776 15.625 L 0.45 18.724 C 0.175 18.885 0.007 19.18 0.007 19.5 C 0.007 19.82 0.175 20.115 0.45 20.276 L 5.776 23.375 L 0.457 26.466 C 0.179 26.626 0.008 26.924 0.008 27.246 C 0.008 27.568 0.179 27.866 0.457 28.026 L 19.02 38.809 C 19.154 38.88 19.304 38.915 19.455 38.91 C 19.613 38.909 19.768 38.867 19.905 38.788 L 38.468 28.005 C 38.742 27.842 38.91 27.545 38.91 27.225 C 38.91 26.904 38.742 26.607 38.468 26.445 Z M 2.677 19.5 L 7.554 16.668 L 18.977 23.325 C 19.111 23.404 19.264 23.446 19.42 23.447 C 19.578 23.445 19.732 23.403 19.869 23.325 L 31.293 16.668 L 36.233 19.5 L 19.455 29.255 Z M 19.455 36.998 L 2.677 27.25 L 7.554 24.374 L 18.977 31.031 C 19.25 31.193 19.589 31.193 19.862 31.031 L 31.321 24.374 L 36.198 27.25 Z\" fill=\"rgba(255, 255, 255, 0.8)\" opacity=\"0.8\"></path></svg>"} svgContentId={1017153556} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1Ogk5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1Ogk5 * { box-sizing: border-box; }", ".framer-1Ogk5 .framer-wt18an { display: block; }", ".framer-1Ogk5 .framer-12qxihx { height: 39px; overflow: hidden; position: relative; width: 39px; }", ".framer-1Ogk5 .framer-1u5hy5e { flex: none; height: 39px; left: calc(48.71794871794874% - 39px / 2); position: absolute; top: calc(48.71794871794874% - 39px / 2); width: 39px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 39
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramergZRmLxrnT: React.ComponentType<Props> = withCSS(Component, css, "framer-1Ogk5") as typeof Component;
export default FramergZRmLxrnT;

FramergZRmLxrnT.displayName = "hero uxui rack animation";

FramergZRmLxrnT.defaultProps = {height: 39, width: 39};

addFonts(FramergZRmLxrnT, [])